<template>
    <div class="tw-relative">
        <OverlayLoader v-if="loading.isLoading('interaction.create')" />
        <div class="tw-relative tw-max-h-[80vh] tw-w-80 tw-space-y-3 tw-overflow-y-auto tw-p-4">
            <WidgetSummary>
                {{ widget.click_to_call_introduction_text }}
            </WidgetSummary>
            <div class="tw-text-xs tw-text-mineShaft/60" v-if="areaName && privatePolicyLink">
                <p v-html="t('widget.live_privacy_policy', [areaName, privatePolicyLink])" />
            </div>
            <form
                @submit.prevent="
                    triedSubmission = true;
                    onSubmit();
                "
            >
                <InputRichText
                    class="tw-mt-5"
                    v-model="values['user_identity']"
                    :error="submitCount > 0 ? errors['user_identity'] : undefined"
                    :label="t('widget.form.fields.phone_number')"
                />
                <input type="submit" hidden />
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
    import InputRichText from '@/components/live/forms/InputRichText.vue';
    import OverlayLoader from '@/components/live/OverlayLoader.vue';
    import WidgetSummary from '@/components/live/WidgetSummary.vue';
    import { useLoader } from '@/composables/wait';
    import { useInteractionStore } from '@/store/interaction';
    import { useField, useForm } from 'vee-validate';
    import { useRoute, useRouter } from 'vue-router';
    import { object, string } from 'yup';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';
    import { computed, ref } from 'vue';
    import { toast } from '@/composables/toast';
    import { useI18n } from 'vue-i18n';

    const widgetStore = useWidgetStore();
    const { widget } = storeToRefs(widgetStore);
    const areaName = computed(() => widget.value.area.name);
    const privatePolicyLink = computed(() => {
        return widget.value.area?.live_privacy_policy_file?.content_url ?? widget.value.area?.live_privacy_policy_url;
    });
    const route = useRoute();
    const router = useRouter();
    const interactionStore = useInteractionStore();
    const { loading } = useLoader();
    const triedSubmission = ref(false);
    const { t } = useI18n();

    const validationSchema = object({
        user_identity: string().required(),
    });

    const { handleSubmit, errors, values, submitCount } = useForm({
        validationSchema,
        initialValues: {
            user_identity: '',
        },
    });

    useField('user_identity');

    const onSubmit = handleSubmit(async (values) => {
        if (loading.isLoading('interaction.create')) return;
        try {
            await interactionStore.createClickToCall(values, route.params.id);
            router.replace({
                name: `click-to-call-end`,
            });
            loading.stop('interaction.create');
        } catch (e) {
            if (e.response?.status === 503) {
                localStorage?.setItem('show-unavailability-msg', 'true');
                window.location = '/' + route.params.id;
            } else {
                toast({
                    text: e.customErrorMsg ?? e.toString(),
                    type: 'error',
                });
                loading.stop('interaction.create');
            }
        }
    });
</script>
