import axios from '@/axios';
import { castArray, omit } from 'lodash';
import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';
import type { RouteRecordName } from 'vue-router';

interface StartInteractionResponse extends FrontWidgetAvailabilities {
    widget: Widget;
}

export const useWidgetStore = defineStore('widget', () => {
    const widget = ref<Widget>();
    const expanded = ref(true);
    const fromFailedFormSubmit = localStorage?.getItem('show-unavailability-msg') === 'true';
    const backRouteName = ref<RouteRecordName | null | undefined>();
    const availabilities = ref<FrontWidgetAvailabilities>({
        click_to_call_state: {
            available: false,
            reason: 'widget_unavailable',
        },
        conversation_state: {
            available: false,
            reason: 'widget_unavailable',
        },
        video_state: {
            available: false,
            reason: 'widget_unavailable',
        },
    });
    localStorage?.removeItem('show-unavailability-msg');

    const updateExpanded = (value: boolean) => {
        expanded.value = value;
    };

    const updateBackRouteName = (value: RouteRecordName | null | undefined) => {
        backRouteName.value = value;
    };

    const isAvailableClickToCall = computed(() => {
        return availabilities.value.click_to_call_state.available;
    });

    const isAvailableConversation = computed(() => {
        return availabilities.value.conversation_state.available;
    });

    const isAvailableVideo = computed(() => {
        return availabilities.value.video_state.available;
    });

    const fallbackLink = computed<string | null>(() => {
        let link = null;
        if (widget.value?.fallback_policy === 'meeting' && widget.value?.fallback_meeting) {
            link = widget.value?.fallback_meeting.front_actions.view;
        } else if (widget.value?.fallback_policy === 'hub' && widget.value?.fallback_hub) {
            link = widget.value?.fallback_hub.front_actions.view;
        }
        if (link) {
            const urlObject = new URL(link);
            link = urlObject.pathname;
        }

        return (!isAvailableConversation.value && !isAvailableConversation.value) ||
            widget.value?.show_fallback_even_when_widget_is_available
            ? link
            : null;
    });

    const isAvailable = computed(() => {
        return isAvailableClickToCall.value || isAvailableConversation.value || isAvailableVideo.value;
    });

    const summaryText = computed(() => {
        if (isAvailable.value && widget.value?.introduction_text) {
            return widget.value?.introduction_text;
        } else if (
            (!isAvailable.value && widget.value?.fallback_policy === 'message') ||
            (!isAvailable.value && widget.value?.fallback_policy !== 'message' && fromFailedFormSubmit)
        ) {
            // when both channels are unavailable, same state reason is returned from both channels
            return availabilities.value.conversation_state.reason === 'no_consultant_available'
                ? widget.value.fallback_messages.no_consultant_available
                : widget.value.fallback_messages.widget_unavailable;
        } else if (!isAvailable.value && widget.value?.fallback_policy !== 'message' && widget.value?.introduction_text) {
            return widget.value?.introduction_text;
        } else {
            return null;
        }
    });

    const fetchWidget = async (routeId: string | string[] = 'widget_all_channels'): Promise<StartInteractionResponse> => {
        const id = castArray(routeId)[0];
        const response = await axios.request<any, StartInteractionResponse>({
            method: 'get',
            url: 'api/live/' + id,
        });
        widget.value = response.widget;
        availabilities.value = omit(response, 'widget');
        return response;
    };

    const setColors = () => {
        if (widget.value?.area?.primary_color) {
            document.documentElement.style.setProperty('--color-primary', widget.value.area.primary_color);
        }
        if (widget.value?.area?.secondary_color) {
            document.documentElement.style.setProperty('--color-secondary', widget.value.area.secondary_color);
        }
    };

    return {
        widget,
        availabilities,
        isAvailableClickToCall,
        isAvailableConversation,
        isAvailableVideo,
        isAvailable,
        fallbackLink,
        summaryText,
        fetchWidget,
        setColors,
        expanded: readonly(expanded),
        backRouteName: readonly(backRouteName),
        updateExpanded,
        updateBackRouteName,
        fromFailedFormSubmit,
    };
});
