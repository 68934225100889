import { useInteractionStore } from '@/store/interaction';
import { useWidgetStore } from '@/store/widget';
import HiddenView from '@/views/live/HiddenView.vue';
import MainView from '@/views/live/MainView.vue';
import MinifiedView from '@/views/live/MinifiedView.vue';
import MessagingContentView from '@/views/live/chat/MessagingContentView.vue';
import MessagingView from '@/views/live/chat/MessagingView.vue';
import ClickToCallFormView from '@/views/live/clickToCall/ClickToCallFormView.vue';
import ClickToCallView from '@/views/live/clickToCall/ClickToCallView.vue';
import EndView from '@/views/live/commons/EndView.vue';
import FormView from '@/views/live/commons/FormView.vue';
import SatisfactionView from '@/views/live/commons/SatisfactionView.vue';
import VideoAudioContentView from '@/views/live/video/VideoAudioContentView.vue';
import VideoAudioView from '@/views/live/video/VideoAudioView.vue';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'hidden',
            component: HiddenView,
        },
        {
            path: '/:id/',
            name: 'root',
            beforeEnter: async ({ params }) => {
                const widgetStore = useWidgetStore();
                try {
                    if (!widgetStore.widget) {
                        await widgetStore.fetchWidget(params.id);
                        await widgetStore.setColors();
                    }
                } catch (e) {
                    console.error("Le widget n'existe pas/plus ou est désactivé dans votre espace client Apenday");
                    return { name: 'hidden' };
                }
            },
            children: [
                {
                    path: '',
                    name: 'start',
                    component: HiddenView,
                    beforeEnter: ({ params }) => {
                        const widgetStore = useWidgetStore();
                        if (!widgetStore.widget?.enabled) {
                            return;
                        } else if (!widgetStore.isAvailable && widgetStore.widget?.fallback_policy === 'hidden') {
                            return;
                        } else if (widgetStore.widget?.starting_display_mode === 'button') {
                            return { name: 'minified', params: { id: params.id } };
                        } else {
                            widgetStore.updateExpanded(widgetStore.widget?.starting_display_mode === 'expanded');
                            return { name: 'main', params: { id: params.id } };
                        }
                    },
                },
                {
                    path: 'minified',
                    name: 'minified',
                    component: MinifiedView,
                },
                {
                    path: 'main',
                    name: 'main',
                    component: MainView,
                },
                {
                    path: 'messaging',
                    component: MessagingView,
                    meta: {
                        channel: 'messaging',
                    },
                    children: [
                        {
                            path: '',
                            name: 'messaging-form',
                            component: FormView,
                        },
                        {
                            path: 'talk',
                            name: 'messaging-talk',
                            component: MessagingContentView,
                            beforeEnter: (to) => {
                                const interactionStore = useInteractionStore();
                                if (!interactionStore.interaction) {
                                    return { name: 'messaging-form', params: { id: to.params.id } };
                                }
                            },
                        },
                        {
                            path: 'end',
                            name: 'messaging-end',
                            component: EndView,
                        },
                        {
                            path: 'satisfaction',
                            name: 'messaging-satisfaction',
                            component: SatisfactionView,
                        },
                    ],
                },
                {
                    path: 'video',
                    component: VideoAudioView,
                    meta: {
                        channel: 'video',
                    },
                    children: [
                        {
                            path: '',
                            name: 'video-form',
                            component: FormView,
                        },
                        {
                            path: 'talk',
                            name: 'video-talk',
                            component: VideoAudioContentView,
                            beforeEnter: (to) => {
                                const interactionStore = useInteractionStore();
                                if (!interactionStore.interaction) {
                                    return { name: 'video-form', params: { id: to.params.id } };
                                }
                            },
                        },
                        {
                            path: 'end',
                            name: 'video-end',
                            component: EndView,
                        },
                        {
                            path: 'satisfaction',
                            name: 'video-satisfaction',
                            component: SatisfactionView,
                        },
                    ],
                },
                {
                    path: 'click-to-call',
                    component: ClickToCallView,
                    meta: {
                        channel: 'click_to_call',
                    },
                    children: [
                        {
                            path: '',
                            name: 'click-to-call-form',
                            component: ClickToCallFormView,
                        },
                        {
                            path: 'end',
                            name: 'click-to-call-end',
                            component: EndView,
                        },
                    ],
                },
            ],
        },
    ],
});

// router.beforeEach(async (to, from, next) => {
// });

export default router;
